<template>
  <Toast></Toast>
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '450px' }"
    header="Pago a Proveedor"
    :modal="true"
    @hide="closeModal"
    class="p-fluid"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-4">
          <label for=""
            ><strong>Recibo/Orden : # </strong>{{ data_pago.nro_recibo }}</label
          >
        </div>
        <div class="field col-12 md:col-4">
          <label for=""
            ><strong>Por Pagar : </strong>
            {{ convertirNumeroGermanicFormat(data_pago.saldo) }} Bs.</label
          >
        </div>
        <div class="field col-12 md:col-4">
          <label for=""
            ><strong>Saldo : </strong>
            {{
              convertirNumeroGermanicFormat(data_pago.saldo - monto - a_cuenta_metodo_pago)
            }}
            Bs.</label
          >
        </div>
      </div>
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-4">
          <label for="metodo_pago"
            ><strong>M&Eacute;TODO DE PAGO:</strong><small class="p-invalid">*</small></label
          >
          <Dropdown
            id="metodo_pago"
            v-model="metodo_pagoSelect"
            :options="metodo_pagos"
            optionLabel="nombre"
            class="w-full"
            optionValue="id"
            placeholder="Seleccione..."
          />
        </div>
        <div class="field col-12 md:col-4" v-if="metodo_pagoSelect != 1">
          <label for=""><strong>MONTO M.PAGOS:</strong></label>
          <InputNumber
            v-model="a_cuenta_metodo_pago"
            :minFractionDigits="2"
            mode="decimal"
            showButtons
            :inputClass="'text-right'"
            :min="0"
            :max="parseFloat(data_pago.saldo - monto)"
          />
        </div>
        <div class="field col-12 md:col-4">
          <label for=""><strong>EFECTIVO BS:</strong><small class="p-invalid">*</small></label>
          <InputNumber
            v-model="monto"
            :minFractionDigits="2"
            mode="decimal"
            showButtons
            :inputClass="'text-right'"
            :min="0"
            :max="parseFloat(data_pago.saldo - a_cuenta_metodo_pago)"
          />
        </div>
        <div class="field col-12 md:col-12">
          <label for=""><strong>OBSERVACI&Oacute;N:</strong></label>
          <Textarea
            v-model="data_pago.observacion"
            :autoResize="true"
            placeholder="observacion"
          />
        </div>
      </div>
    </div>
    <template #footer>
      <div class="field">
        <label class="flex md:justify-content-start"
          >Campos Requeridos: <span class="p-invalid">*</span></label
        >
      </div>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="closeModal"
        :disabled="!enviado ? false : true"
      />
      <Button
        label="Guardar"
        icon="pi pi-check"
        class="p-button-primary p-button-lg"
        @click="guardarPagoProveedor"
        :disabled="!enviado ? false : true"
        :loading="enviado"
      />
    </template>
  </Dialog>
</template>
<script>
import PagoProveedorService from "@/service/PagoProveedorService.js";
import MetodoPagoService from "@/service/MetodoPagoService.js";

export default {
  name: "PagoProveedor",
  emits: ["closeModal", "actualizarListado"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    pago_proveedor: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      mostrarModal: this.show,
      data_pago: this.pago_proveedor,
      enviado: false,
      errors: {},
      metodo_pagos: [],
      metodo_pagoSelect: null,
      a_cuenta_metodo_pago: 0,
      monto: 0,
    };
  },
  pagoProveedorService: null,
  metodoPagoService: null,

  created() {
    this.pagoProveedorService = new PagoProveedorService();
    this.metodoPagoService = new MetodoPagoService();
  },
  mounted() {
    this.getMetodoPagos();
  },
  methods: {
    getMetodoPagos() {
      this.metodoPagoService.getAllMetodoPagos().then((response) => {
        this.metodo_pagos = response.metodo_pagos;
      });
    },
    guardarPagoProveedor() {
      this.enviado = true;
      // Preparo los datos para enviar
      let datos = {
        id: this.data_pago.id,
        monto: this.monto,
        saldo: this.data_pago.saldo,
        metodo_pago_id: this.metodo_pagoSelect, // Save the id of the selected option
        a_cuenta_metodo_pago: this.a_cuenta_metodo_pago, // Save the a_cuenta_metodo_pago of the selected option
        observacion: this.data_pago.observacion,
      };
      // verifico si el monto a pagar es mayor al saldo
      if (datos.monto > datos.saldo) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "El monto a pagar no puede ser mayor al saldo",
          life: 3000,
        });
        this.enviado = false;
        return;
      }
      if(this.metodo_pagoSelect == null){
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Debe seleccionar un metodo de pago",
          life: 3000,
        });
        this.enviado = false;
        return;
      }

      this.pagoProveedorService.actualizoPagoProveedor(datos).then(() => {
        this.enviado = false;
        this.$emit("actualizarListado");
        this.closeModal();
      });
    },
    closeModal() {
      this.$emit("closeModal");
      this.enviado = false;
      this.monto = 0;
      this.a_cuenta_metodo_pago = 0;
      this.metodo_pagoSelect = null;
      this.observacion = "";
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
  },

  watch: {
    show(value) {
      this.mostrarModal = value;
    },
    pago_proveedor(val) {
      this.data_pago = val;
    },
    metodo_pagoSelect() {
      if(this.metodo_pagoSelect == 1){
        this.monto = parseFloat(this.data_pago.saldo);
        this.a_cuenta_metodo_pago = 0;
      } else {
        this.a_cuenta_metodo_pago = parseFloat(this.data_pago.saldo);
        this.monto = 0;
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";

.p-invalid {
  color: red;
}
</style>